import { useEffect, useState, } from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Box, Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import NorthEastIcon from '@mui/icons-material/NorthEast';

import UploadRelease from './UploadRelease'
import RegisteredIndex from './RegisteredIndex'
import { LoadingProvider } from '../provider/LoadingProvider';


const apiScope = `${process.env.REACT_APP_API_SCOPE}`;

const ReleaseFiles = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const [cog_token, setCogToken] = useState(null);

    useEffect(() => {
        async function acquireOpenAIToken() {
            try {
                const account = instance.getAllAccounts()[0];
                if (!account) {
                    throw new Error("User not logged in.");
                }

                const tokenRequest = {
                    scopes: [apiScope],
                    account: account
                };
                try {
                    const response = await instance.acquireTokenSilent(tokenRequest);
                    setCogToken(response.accessToken);
                    return response.accessToken;
                } catch (error) {
                    console.error("Error acquiring token:", error);
                    throw error;
                }
            } catch (error) {
                console.error('Error acquiring OpenAI token or calling ChatCompletion API:', error);
            }
        }
        acquireOpenAIToken();
    }, [isAuthenticated]);

    return (
        <div className="homePage">
            <UnauthenticatedTemplate>
                <Box m={4} display="flex" alignItems="center" >
                    <Box width={'100%'}>
                        <Typography align='center'>
                            ログインして利用開始<NorthEastIcon />
                        </Typography>
                    </Box>
                </Box>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <LoadingProvider>
                    <Container sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <UploadRelease cog_token={cog_token} />
                            </Grid>
                            <Grid item xs={8}>
                                <RegisteredIndex cog_token={cog_token} />
                            </Grid>
                        </Grid>
                    </Container>
                </LoadingProvider>
            </AuthenticatedTemplate >
        </div >

    )
};

export default ReleaseFiles