import React, { createContext, useState, useContext } from 'react';

// Contextの作成
const LoadingContext = createContext();

// Providerコンポーネント
export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <LoadingContext.Provider value={[isLoading, setIsLoading]}>
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => useContext(LoadingContext);