import { useEffect, useState, } from 'react'
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TransitionAlerts } from './TransitionAlerts'
import CircularProgress from '@mui/material/CircularProgress';

import { DataGrid } from '@mui/x-data-grid';

import Paper from '@mui/material/Paper';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Link from '@mui/material/Link';
import { CategoryDefinition } from './Category'
import ShowMessage from '../components/ShowMessage';
import { getErrorMessage } from '../components/ErrorMessages';
import { useLoading } from '../provider/LoadingProvider';

const apiListReleases = `${process.env.REACT_APP_API_LIST_RELEASES}`;
const apiUrlDownload = `${process.env.REACT_APP_API_DOWNLAOD_URL}`;

const FileDownloadLink = (props) => {
    const release_id = props.release_id;
    const name = props.name;
    const cog_token = props.cog_token;

    const downloadFile = (filepath) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + cog_token,
        };

        var url = apiUrlDownload + "?path=" + encodeURIComponent(filepath)

        fetch(url, { headers: headers }) // FETCH BLOB FROM IT
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                var _url = window.URL.createObjectURL(blob);
                window.open(_url, "_blank").focus(); // window.open + focus
            }).catch((err) => {
                console.log(err);
            });
    }

    return (
        name != "" ?
            <Link href="#" onClick={() => { downloadFile('original/' + release_id + "/" + name) }} >{name}</Link>
            : null
    )


};
const RegisteredIndex = (props) => {
    const [isLoading, setIsLoading] = useLoading();
    //const [lastUpdateTime, setLastUpdateTime] = useState(new Date());  // 画面描画を強制Updateする
    const [notifyMessage, setNotifyMessage] = useState("")
    const [files, setFiles] = useState([]);
    const cog_token = props.cog_token;

    // 通知管理用
    const [msg, setMsg] = useState({
        open: false,
        isSuccess: true,
        message: "",
    });

    // 削除処理
    const handleDelete = async(release_id) => {
        try {
            setIsLoading(true);
            if (window.confirm(`本当にリリースID: ${release_id} を削除してよろしいですか？\n(削除した文書をもとに戻すことは出来ません)`)) {
                const headers = {
                    'Authorization': `Bearer ` + cog_token
                };
                const response = await fetch(`${apiListReleases}/${release_id}`, { headers: headers, method: "DELETE" })
                const result = await response.json();
                
                if (response.ok) {
                    // クリックした行を削除
                    setFiles((files) => files.filter((file) => file.id !== release_id));
                    setMsg({open: true, isSuccess: true,
                        message: `リリースID: ${release_id} の削除処理が成功しました。`
                    });
                } else {
                    setMsg({open: true, isSuccess: false,
                        message: getErrorMessage(result.code)
                    });
                }
                setIsLoading(false);
            }else {
                setIsLoading(false);
            }
            
        } catch (e) {
            if (e instanceof TypeError) {
                setMsg({open: true, isSuccess: false,
                    message: getErrorMessage('99998')
                });
              } else {
                setMsg({open: true, isSuccess: false,
                    message: getErrorMessage('99999')
                });
              }
            setIsLoading(false);
        }
    };

    const columns = [
        {
            field: 'actions',
            headerName: '',
            width: 80,
            renderCell: (params) => (
                <Button variant='text' color='error' 
                    onClick={() => handleDelete(params.row.id)} disabled={isLoading} 
                    data-testid='delete-button-element'>
                    <DeleteOutlinedIcon/>
                </Button>
            )
        }
        , { field: 'id', headerName: 'リリースID' }
        , {
            field: 'release_date',
            headerName: 'リリース日付',
            width: 100,
            renderCell: (params) => { return formatDate(params.value) }
        }
        , {
            field: 'release_title',
            headerName: 'リリースタイトル',
            width: 200
        }
        ,{
            field: 'release_category',
            headerName: 'カテゴリ',
            width: 140,
            renderCell: (params) => {
                var comp = []
                //params.row.release_category
                return (<>{CategoryDefinition[params.row.release_category]}</>);
            }
        }
        , {
            field: 'other_files',
            headerName: '登録文書',
            width: 600,
            renderCell: (params) => {
                var comp = []
                params.row.files.map((f) => {
                    comp.push(<> - <FileDownloadLink cog_token={cog_token} release_id={params.row.id} name={f}></FileDownloadLink><br /></>)
                })
                return (<>{comp}</>);
            }
        }
    ]
    const listReleases = async () => {
        try {
            setIsLoading(true);
            setFiles([]);
            const headers = {
                'Authorization': `Bearer ` + cog_token
            };
            const response = await fetch(apiListReleases, { headers: headers, method: "GET" })
            const json = await response.json();

            setFiles(json);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.error('Error calling List Releases API:', error);

            setNotifyMessage((new Date().toUTCString()) + " " + error.stack);
        }
    }

    useEffect(() => {
        if (cog_token != null)
            listReleases();
    }, [cog_token]);

    const formatDate = (date) => {
        // 20241231のような数値を日付文字列に変換する
        const year = Math.trunc(date / 10000)
        const month = Math.trunc((date - year * 10000) / 100)
        const day = Math.trunc(date - year * 10000 - month * 100)
        return year + "/" + month + "/" + day;
    }

    return (
        <AuthenticatedTemplate>
            <Paper sx={{ p: 2 }} >
                <>{notifyMessage.length > 0 ?
                    <TransitionAlerts message={notifyMessage}></TransitionAlerts>
                    : null}</>
                <Typography sx={{ fontWeight: 600, fontSize: 'large' }}>登録済み情報</Typography>
                {isLoading ? (
                    <Typography align='center'><CircularProgress size={30} /></Typography>
                ) : (
                    <Button onClick={listReleases} startIcon={<UpdateIcon />} variant='outlined'>更新</Button>
                )
                }
                <DataGrid
                    rows={files}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 50,
                            },
                        },
                    }}
                    pageSizeOptions={[50]}
                    getRowHeight={() => 'auto'}
                >
                </DataGrid>
            </Paper>
            <ShowMessage
                open={msg.open}
                isSuccess={msg.isSuccess}
                message={msg.message}
                handleClose={() => setMsg({ open: false, isSuccess: msg.isSuccess, message: "" })}
            ></ShowMessage>
        </AuthenticatedTemplate>
    )
};

export default RegisteredIndex