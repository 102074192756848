import { Alert, Snackbar } from "@mui/material";

function ShowMessage({
  open,
  isSuccess,
  message,
  handleClose,
}: {
  open: boolean;
  isSuccess: boolean;
  message: string;
  handleClose?: () => void;
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={handleClose}
      // autoHideDuration={60000}
    >
      <Alert
        onClose={handleClose}
        severity={isSuccess ? 'success' : 'error'}
        variant="filled"
        sx={{ width: "100%", whiteSpace: "pre-line" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default ShowMessage;
