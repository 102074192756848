type ErrorMessages = {
    [key: string]: string;
};

export const errorMessages: ErrorMessages = {
    '10101': '操作中にエラーが発生しました。管理者へお問い合わせください。\nエラーコード：<{code}>',
    '10102': '操作中にエラーが発生しました。管理者へお問い合わせください。\nエラーコード：<{code}>',
    '10103': '該当の文書は既に削除されています。',
    '10201': '操作中にエラーが発生しました。管理者へお問い合わせください。\nエラーコード：<{code}>',
    '10202': '操作中にエラーが発生しました。管理者へお問い合わせください。\nエラーコード：<{code}>',
    '10301': '操作中にエラーが発生しました。管理者へお問い合わせください。\nエラーコード：<{code}>',
    '10302': '操作中にエラーが発生しました。管理者へお問い合わせください。\nエラーコード：<{code}>',
    '10901': 'セッションの期限が切れました。再度ログインしてください。\nエラーコード：<{code}>',
    '99998': '予期せぬエラーが発生しました。管理者へお問い合わせください。\nエラーコード：<{code}>',
    '99999': '予期せぬエラーが発生しました。管理者へお問い合わせください。\nエラーコード：<{code}>'
};

export function getErrorMessage(errorCode: string): string {
    const messageTemplate = errorMessages[errorCode] || errorMessages['99999'];
    return messageTemplate.replace('{code}', errorCode);
}
